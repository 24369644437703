import { useEffect, useRef, useState } from "react";
import "./ImageGallery.css";

const OppThumbnailGallery = ({
  OpportunityImageSources,
  alldata,
  imageGallerySelectedTab,
  setImageGalleryItemCurrentIndex,
}) => {
  const scrollRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const checkScrollability = () => {
    if (scrollRef.current) {
      setCanScrollLeft(scrollRef.current.scrollLeft > 0);
      setCanScrollRight(
        scrollRef.current.scrollLeft + scrollRef.current.clientWidth <
          scrollRef.current.scrollWidth - 1
      );
    }
  };

  useEffect(() => {
    checkScrollability();
    window.addEventListener("resize", checkScrollability);
    return () => window.removeEventListener("resize", checkScrollability);
  }, [OpportunityImageSources, alldata, imageGallerySelectedTab]);

  const handleScroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = scrollRef.current.clientWidth;
      scrollRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });

      setTimeout(checkScrollability, 300); // Delay to allow scrolling to take effect
    }
  };

  const images =
    OpportunityImageSources.find(
      (item) => item.opportunityId === alldata.id
    )?.imageJson.gallerySections[imageGallerySelectedTab]?.gallery.map(
      (item) => ({
        original: item.src,
        thumbnail: item.src,
      })
    ) || [];

  return (
    <div className="thumbnail-container">
      {canScrollLeft && (
        <button
          className="scroll-button left"
          onClick={() => handleScroll("left")}
        >
          &#8249;
        </button>
      )}
      <div className="thumbnail-grid-wrapper">
        <div
          className="thumbnail-grid"
          ref={scrollRef}
          onScroll={checkScrollability}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image.thumbnail}
              className="thumbnail"
              onClick={() => setImageGalleryItemCurrentIndex(index)}
              alt=""
            />
          ))}
        </div>
      </div>
      {canScrollRight && (
        <button
          className="scroll-button right"
          onClick={() => handleScroll("right")}
        >
          &#8250;
        </button>
      )}
    </div>
  );
};

export default OppThumbnailGallery;
