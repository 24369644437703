export const OpportunityVideoSources = [
  {
    opportunityId: 191,
    roundId: 827,
    videoJson: {
      headerText: "Insights from Bajaj Leaders",
      subheaderText: "",
      videos: [
        {
          id: 1,
          url: "https://www.youtube.com/watch?v=H7PzaxyOx0I",
          title: "PS1_BFL_Baja Finance Limited",
          thumbnail: "https://img.youtube.com/vi/H7PzaxyOx0I/hqdefault.jpg",
        },
        {
          id: 2,
          url: "https://www.youtube.com/watch?v=4Ecr5dHpPp4",
          title: "PS2_BALIC_Bajaj Allianz Life Insurance Company",
          thumbnail: "https://img.youtube.com/vi/4Ecr5dHpPp4/hqdefault.jpg",
        },
        {
          id: 3,
          url: "https://www.youtube.com/watch?v=dedju9iN6Dw",
          title: "PS3_BAGIC_Bajaj Allianz General Insurance Company",
          thumbnail: "https://img.youtube.com/vi/dedju9iN6Dw/hqdefault.jpg",
        },
        {
          id: 4,
          url: "https://www.youtube.com/watch?v=WZKdDC9eHQM",
          title: "SPS4_BFHL_Bajaj Finserv Health Limited",
          thumbnail: "https://img.youtube.com/vi/WZKdDC9eHQM/hqdefault.jpg",
        },
        {
          id: 5,
          url: "https://www.w3schools.com/html/mov_bbb.mp4",
          title: "PS6_Video Without Youtube Src",
          thumbnail: "https://img.youtube.com/vi/iyJOXNsOye0/hqdefault.jpg",
        },
      ],
    },
  },
];
