import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";

function VideoGallery({ videos }) {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [durations, setDurations] = useState({});
  const playerRefs = useRef({}); // Stores player refs for each video

  // Format duration (seconds → MM:SS)
  const formatDuration = (seconds) => {
    if (!seconds) return "00:00";
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        alignItems: "baseline",
        gap: "20px",
        padding: "20px",
      }}
    >
      {videos.map((video) => (
        <div key={video.id} style={{ cursor: "pointer", maxWidth: "320px" }}>
          <div
            style={{
              position: "absolute",
              visibility: "hidden",
              width: 0,
              height: 0,
            }}
          >
            <ReactPlayer
              url={video.url}
              ref={(player) => (playerRefs.current[video.id] = player)}
              onReady={() => {
                const duration = playerRefs.current[video.id]?.getDuration();
                if (duration) {
                  setDurations((prev) => ({
                    ...prev,
                    [video.id]: formatDuration(duration),
                  }));
                }
              }}
            />
          </div>

          {/* Video Thumbnail */}
          <div
            onClick={() => setSelectedVideo(video.url)}
            style={{
              position: "relative",
              borderRadius: "8px",
              overflow: "hidden",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <img
              src={video.thumbnail}
              alt={video.title}
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />

            {/* Video Duration Overlay */}
            <div
              style={{
                position: "absolute",
                bottom: "8px",
                right: "8px",
                background: "rgba(0, 0, 0, 0.7)",
                color: "#fff",
                padding: "5px 8px",
                borderRadius: "5px",
                fontSize: "14px",
              }}
            >
              {durations[video.id] || "00:00"}
            </div>
          </div>

          {/* Video Title */}
          <div
            style={{
              marginTop: "10px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#333",
              textAlign: "left",
            }}
          >
            {video.title}
          </div>
        </div>
      ))}

      {/* Overlay Player */}
      {selectedVideo && (
        <div
          onClick={() => setSelectedVideo(null)}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              position: "relative",
              background: "#000",
              borderRadius: "10px",
              padding: "10px",
              boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
            }}
          >
            <button
              onClick={() => setSelectedVideo(null)}
              style={{
                position: "absolute",
                top: "-40px",
                right: "0px",
                background: "transparent",
                color: "#fff",
                fontSize: "30px",
                border: "none",
                cursor: "pointer",
              }}
            >
              ✖
            </button>
            <ReactPlayer
              url={selectedVideo}
              controls
              playing
              width="900px"
              height="510px"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoGallery;
