export const OpportunityImageSources = [
  {
    opportunityId: 191,
    imageJson: {
      headerText: "Previous Editions in Frames",
      subheaderText: "",
      gallerySections: [
        {
          title: "Season 7",
          gallery: [
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S7/DSC07872.jpg",
              alt: "Image 1",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S7/DSC08318.jpg",
              alt: "Image 2",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S7/DSC08657.jpg",
              alt: "Image 3",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S7/DSC08668.jpg",
              alt: "Image 3",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S7/DSC08674.jpg",
              alt: "Image 3",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S7/DSC08733.jpg",
              alt: "Image 3",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S7/DSC08910.jpg",
              alt: "Image 3",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S7/DSC08996.jpg",
              alt: "Image 3",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S7/DSC09050.jpg",
              alt: "Image 3",
            },
          ],
        },
        {
          title: "Season 6",
          gallery: [
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S6/012A1222.JPG",
              alt: "Image 1",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S6/012A1274.JPG",
              alt: "Image 1",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S6/012A1329.JPG",
              alt: "Image 1",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S6/012A1438.JPG",
              alt: "Image 1",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S6/012A1447.JPG",
              alt: "Image 1",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S6/012A1527.JPG",
              alt: "Image 1",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S6/012A1542.JPG",
              alt: "Image 1",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S6/012A1560.JPG",
              alt: "Image 1",
            },
            {
              src: "https://gradimages.s3.ap-south-1.amazonaws.com/ATOM+Pictures/ATOM+S6/012A1561.JPG",
              alt: "Image 1",
            },
          ],
        },
      ],
    },
  },
];
