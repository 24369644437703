import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight, FaSave } from "react-icons/fa";
import { IoMdClose, IoMdCreate, IoMdSettings, IoMdTrash } from "react-icons/io";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../../CommonFiles/config.json";
import Modal from "../../CommonFiles/UI Elements/Modal/Modal";
import Tooltip from "../../CommonFiles/UI Elements/Tooltip/Tooltip";
import "../CustomRegistrationForm.css";
import CustomFieldModal from "./CustomFieldModal";
import EligibilityConfigModal from "./EligibilityConfigModal";
import { transformData } from "./EligibilityOptions/CollegeStudentsFiltersTransformed";
import EligibilityConfigPreview from "./EligibilityOptions/EligibilityConfigPreview";

const mainURL = Object.values(config["URL"]).join("");
const DefaultFields = [
  {
    label: "Team Name",
    fieldType: "text",
    fieldName: "team_name",
    placeholder: "Enter Your Team Name",
    isMandatory: true,
  },
  {
    label: "Member's Name",
    fieldType: "text",
    fieldName: "members_name",
    placeholder: "Enter Member's Name",
    isMandatory: true,
  },
  {
    label: "Member's Email",
    fieldType: "email",
    fieldName: "members_email",
    placeholder: "Enter Member's Email",
    isMandatory: true,
    allowedDomains: [],
  },
  {
    label: "Member's Mobile No",
    fieldType: "number",
    fieldName: "members_mobile_no",
    placeholder: "Enter Member's Mobile Number",
    isMandatory: true,
    rules: [{ maxLength: 10, minLength: 10 }],
  },
  {
    label: "Institute / Organization",
    fieldType: "select",
    fieldName: "institute_organization",
    isMandatory: true,
    options: [],
    excluded_options: [],
  },
  {
    label: "Gender",
    fieldType: "select",
    fieldName: "gender",
    isMandatory: true,
    options: [
      "Female",
      "Male",
      "Transgender",
      "Non-binary/non-confirming",
      "Prefer not to respond",
    ],
  },
  {
    label: "Add Eligibility Filter",
    fieldType: "radio",
    fieldName: "use_eligibility_filter",
    options: ["Yes", "No"],
    value: "No",
    config: null,
  },
];

function StepNine(props) {
  const [regFormSettings, setRegFormSettings] = useState(DefaultFields);
  const [formData, setFormData] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [registerOnGP, setRegisterOnGP] = useState("Yes");
  const [registrationLink, setRegistrationLink] = useState("");
  const [registrationLinkError, setRegistrationLinkError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [editingField, setEditingField] = useState(null);
  const [collegeStudentsFilters, setCollegeStudentsFilters] = useState([]);
  const [isEligibilityConfigModalOpen, setIsEligibilityConfigModalOpen] =
    useState(false);
  const [isEmailDomainModalOpen, setIsEmailDomainModalOpen] = useState(false);
  const [emailDomain, setEmailDomain] = useState("@");
  const history = useHistory();

  // Load Data
  useEffect(() => {
    if (localStorage.getItem("editopportunityid")) {
      axios({
        method: "get",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      })
        .then((response) => {
          const alldata = response.data.data;
          if (alldata.external_registration === 0) {
            setRegisterOnGP("Yes");
            setRegFormSettings(
              alldata.registration_form_settings
                ? JSON.parse(alldata.registration_form_settings)
                : DefaultFields
            );
          } else {
            setRegisterOnGP("No");
            setRegistrationLink(alldata.registration_link ?? "");
          }

          axios({
            method: "get",
            url: mainURL + "/courses",
          }).then((response) => {
            const filters = transformData(
              response.data.filter((item) => item.specializationName !== "All")
            );
            setCollegeStudentsFilters(filters);
            setIsLoading(false);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  // This is to open the links (if any added by user) in the tnc field in a new tab
  useEffect(() => {
    // Find all .tnc-text containers
    const containers = document.querySelectorAll(".tnc-text");
    containers.forEach((container) => {
      const links = container.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
      });
    });
  }, [regFormSettings]);

  const handleChange = (e, rules) => {
    const { name, value } = e.target;

    if (rules) {
      const maxLengthRule = rules.find((rule) => rule.maxLength !== undefined);
      if (maxLengthRule && value.length > maxLengthRule.maxLength) {
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveField = (newField) => {
    if (newField.fieldName === "institute_organization") {
      const allOrgs = newField.options.concat(newField.excluded_options);
      console.log(allOrgs); // TODO: Add API call to save new orgs
    }

    // axios({
    //   method: "post",
    //   url: mainURL+"/v1/collages",
    //   data: {
    //     names: [...newOrgs],
    //   },
    // })

    if (editingField) {
      setRegFormSettings(
        regFormSettings.map((field) => {
          if (field.fieldName === editingField.fieldName) {
            return newField;
          }
          return field;
        })
      );
      setEditingField(null);
      return;
    } else {
      setRegFormSettings([...regFormSettings, newField]);
      setEditingField(null);
    }
  };

  const handleLinkChange = (val) => {
    if (val.length > 0 && val !== "https://") {
      if (val.includes("http://")) {
        val = val.split("http://");
        val = "https://" + val[1];
      } else {
        if (!val.includes("https://")) {
          val = "https://" + val;
        }
      }
      val = val.split(" ").join("");
    } else {
      val = "";
    }
    setRegistrationLink(val);
  };

  const renderField = (field) => {
    switch (field.fieldType) {
      case "text":
      case "email":
      case "number":
        return (
          <input
            className="form-control"
            type={field.fieldType}
            name={field.fieldName}
            placeholder={field.placeholder}
            onChange={(e) => handleChange(e, field?.rules)}
            value={formData[field.fieldName]}
          />
        );
      case "radio":
        return (
          <div className="radio-field">
            {field.options.map((option, index) => (
              <label key={index}>
                {field.fieldName === "use_eligibility_filter" ? (
                  <input
                    type="radio"
                    name={field.fieldName}
                    value={option}
                    checked={
                      regFormSettings.find(
                        (setting) => setting.fieldName === field.fieldName
                      ).value === option
                    }
                    onChange={() =>
                      setRegFormSettings(
                        regFormSettings.map((setting) => {
                          if (setting.fieldName === field.fieldName) {
                            return {
                              ...setting,
                              value: option,
                            };
                          }
                          return setting;
                        })
                      )
                    }
                    onClick={() => {
                      if (option === "Yes") {
                        setIsEligibilityConfigModalOpen(true);
                      }
                    }}
                    title={option === "Yes" ? "Click to configure" : ""}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <input type="radio" name={field.fieldName} value={option} />
                )}
                {option}
              </label>
            ))}
          </div>
        );
      case "textarea":
        return (
          <textarea
            className="form-control"
            name={field.fieldName}
            placeholder={field.placeholder}
            maxLength={field.rules?.find((rule) => rule.maxLength)?.maxLength}
          />
        );
      case "checkbox":
        return (
          <div className="custom-field-checkbox-container">
            {field.options.map((option, index) => (
              <label key={index} className="custom-field-checkbox-item">
                <input
                  type="checkbox"
                  name={field.fieldName}
                  value={option}
                  className="form-control custom-field-checkbox"
                />
                {field.isTnCField ? (
                  <div className="tnc-text">
                    <div dangerouslySetInnerHTML={{ __html: option }} />
                  </div>
                ) : (
                  option
                )}
              </label>
            ))}
          </div>
        );

      case "select":
        return (
          <select className="form-control" name={field.fieldName}>
            {field.fieldName !== "institute_organization" &&
              field.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}

            {/* All Institutes or Exclude Some From All */}
            {field.fieldName === "institute_organization" &&
              field.options.length === 0 &&
              props.allColleges
                .map((c) => c.label)
                .filter((item) => !field.excluded_options.includes(item))
                .map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}

            {/* Only selected institutes */}
            {field.fieldName === "institute_organization" &&
              field.options.length > 0 &&
              field.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
          </select>
        );
      case "file":
        return (
          <input className="form-control" type="file" name={field.fieldName} />
        );
      default:
        return null;
    }
  };

  const isDefaultField = (field) => {
    return DefaultFields.find(
      (fieldData) => fieldData.fieldName === field.fieldName
    );
  };

  const prevStep = () => {
    localStorage.setItem("activestep", 7);
    props.newActiveStepValue(7);
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  const nextStep = (status) => {
    if (isLoading) return;

    if (registerOnGP === "No" && registrationLink.length === 0) {
      setRegistrationLinkError("Please enter a registration link");
      return;
    }

    setRegistrationLinkError("");
    setIsLoading(true);

    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    const payload = {
      external_registration: registerOnGP === "Yes" ? 0 : 1,
      registration_link: registerOnGP === "Yes" ? null : registrationLink,
      registration_form_settings:
        registerOnGP === "Yes"
          ? JSON.stringify(
              regFormSettings.map((f) => {
                if (
                  f.fieldName === "use_eligibility_filter" &&
                  f.value === "No"
                ) {
                  f.config = null;
                }
                return f;
              })
            )
          : null,
    };

    axios({
      method: "put",
      url:
        mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      data: payload,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 1) {
          window.scrollTo({
            top: 300,
            behavior: "smooth",
          });
          if (status === "save") {
            Swal.fire(
              "Good job!",
              "Registration form details have been updated",
              "success"
            );
          } else {
            localStorage.removeItem("FormOne");
            localStorage.removeItem("FormTwo");
            localStorage.removeItem("FormThree");
            localStorage.removeItem("FormFour");
            localStorage.removeItem("FormFive");
            localStorage.removeItem("FormSix");
            localStorage.removeItem("FormSeven");
            localStorage.removeItem("FormNine");
            localStorage.removeItem("activestep");

            history.push({
              pathname: "/user/hosting",
            });
          }
        }
      })
      .catch(function (response) {
        Swal.fire(
          "Sorry!",
          "Some error occured while saving registration form data",
          "error"
        );
      });
  };

  return (
    <div>
      <div className="form">
        <form className="form-body">
          {!isLoading && (
            <>
              {/* Choose Registration Method */}
              <div className="form-row">
                <div className="input-field">
                  <div className="field-label-container">
                    <label>Take registrations on Grad Partners ?</label>
                  </div>
                  <div className="radio-field">
                    <label>
                      <input
                        type="radio"
                        name="external_registration"
                        value={"Yes"}
                        onChange={(e) => setRegisterOnGP(e.currentTarget.value)}
                        checked={registerOnGP === "Yes"}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="external_registration"
                        value={"No"}
                        onChange={(e) => setRegisterOnGP(e.currentTarget.value)}
                        checked={registerOnGP === "No"}
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>

              <br />
              <br />

              {registerOnGP === "No" && (
                <div className="form-row">
                  <div className="input-field">
                    <div className="field-label-container">
                      <label>
                        Registration Link
                        <span className="required-mark"> *</span>
                      </label>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter registration link"
                      onChange={(e) => handleLinkChange(e.target.value)}
                      value={registrationLink}
                    />
                    {registrationLinkError && (
                      <p className="required-mark">{registrationLinkError}</p>
                    )}
                  </div>
                </div>
              )}

              {registerOnGP === "Yes" && (
                <>
                  {/* Registration on Grad Partner */}
                  <div className="form-row">
                    <p className="notifyshow">
                      {" "}
                      <i className="fa fa-exclamation-circle"></i> You can
                      customize your registration form by adding various fields
                      as per you need by clicking on the button 'Add Custom
                      Field' present at the bottom
                    </p>
                  </div>
                  <br />
                  <div className="form-row">
                    <h4>User Registration Form Preview</h4>
                  </div>
                  <div className="registration-form-container">
                    {regFormSettings.map((item, index) => {
                      return (
                        <div
                          className={`input-field ${item.fieldName === "use_eligibility_filter" ? "full-width" : ""}`}
                          key={item.fieldName}
                        >
                          <div className="field-label-container">
                            <label>
                              {item.label}
                              {item.isMandatory ? (
                                <span className="required-mark"> *</span>
                              ) : (
                                ""
                              )}
                            </label>

                            {item.fieldName === "use_eligibility_filter" && (
                              <>
                                {item.value === "Yes" && (
                                  <IoMdCreate
                                    className="delete-field-icon"
                                    title="Edit Eligibility Configuration"
                                    onClick={() => {
                                      setIsEligibilityConfigModalOpen(true);
                                    }}
                                  />
                                )}
                                <Tooltip
                                  text={
                                    "This selection will not be presented to the users, if selected 'Yes', the eligibility fields will be shown to users during registration"
                                  }
                                  textSize="16px"
                                  width="300px"
                                />
                              </>
                            )}

                            {item.fieldName === "members_email" && (
                              <IoMdSettings
                                className="delete-field-icon"
                                title="Configure allowed email domains"
                                onClick={() => {
                                  setIsEmailDomainModalOpen(true);
                                }}
                              />
                            )}

                            {(!isDefaultField(item) ||
                              item.fieldName === "institute_organization") && (
                              <IoMdCreate
                                className="delete-field-icon"
                                title="Edit Custom Field"
                                onClick={() => {
                                  setEditingField(item);
                                  setModalOpen(true);
                                }}
                              />
                            )}

                            {!isDefaultField(item) && (
                              <IoMdTrash
                                className="delete-field-icon"
                                title="Delete Custom Field"
                                onClick={() => {
                                  const array = regFormSettings.concat();
                                  array.splice(index, 1);
                                  setRegFormSettings(array);
                                }}
                              />
                            )}
                          </div>
                          {renderField(item)}

                          {item.fieldName === "use_eligibility_filter" &&
                            item.value === "Yes" &&
                            item.config && (
                              <EligibilityConfigPreview
                                eligibilityConfig={item.config}
                              />
                            )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="add-field-section">
                    <button
                      className="add-field-button btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setModalOpen(true);
                      }}
                    >
                      + Add Custom Field
                    </button>
                  </div>
                </>
              )}
            </>
          )}

          {isLoading && (
            <div className="reg-form-loader-container">
              <div className="reg-form-loader"></div>
            </div>
          )}
        </form>
      </div>
      <CustomFieldModal
        allColleges={props.allColleges}
        isOpen={isModalOpen}
        editingField={editingField}
        existingFields={regFormSettings}
        onClose={() => {
          setModalOpen(false);
          setEditingField(null);
        }}
        onSave={handleSaveField}
      />

      <EligibilityConfigModal
        isOpen={
          regFormSettings.find(
            (setting) => setting.fieldName === "use_eligibility_filter"
          )?.value === "Yes" &&
          collegeStudentsFilters.length > 0 &&
          isEligibilityConfigModalOpen
        }
        onClose={() => {
          setRegFormSettings(
            regFormSettings.map((setting) => {
              if (setting.fieldName === "use_eligibility_filter") {
                return {
                  ...setting,
                  value: setting.config ? "Yes" : "No",
                };
              }
              return setting;
            })
          );
          setIsEligibilityConfigModalOpen(false);
        }}
        CollegeStudentsFilters={collegeStudentsFilters}
        onConfigSave={(newEligibilityConfig) => {
          setRegFormSettings(
            regFormSettings.map((setting) => {
              if (setting.fieldName === "use_eligibility_filter") {
                return {
                  ...setting,
                  value: "Yes",
                  config: newEligibilityConfig,
                };
              }
              return setting;
            })
          );
          setIsEligibilityConfigModalOpen(false);
        }}
        existingConfig={
          regFormSettings.find(
            (setting) => setting.fieldName === "use_eligibility_filter"
          )?.config
        }
      />

      {isEmailDomainModalOpen && (
        <Modal
          isOpen={isEmailDomainModalOpen}
          onClose={() => {
            setEmailDomain("@");
            setIsEmailDomainModalOpen(false);
          }}
          title="Configure allowed email domains"
        >
          <label className="email-domain-label">
            Enter email domain to be allowed then press enter (↵)
          </label>
          <input
            type="text"
            className="form-control"
            value={emailDomain}
            onChange={(e) => {
              if (e.target.value.trim().length === 0) {
                setEmailDomain("@");
              } else {
                const regex = /^[^\s@0-9]*@[^\s@0-9]*$/;
                if (regex.test(e.target.value)) {
                  setEmailDomain(e.target.value);
                }
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setRegFormSettings(
                  regFormSettings.map((setting) => {
                    if (setting.fieldName === "members_email") {
                      return {
                        ...setting,
                        allowedDomains: setting.allowedDomains
                          ? [...setting.allowedDomains, emailDomain]
                          : [emailDomain],
                      };
                    }
                    return setting;
                  })
                );
                setEmailDomain("@");
              }
            }}
          />

          {regFormSettings.find(
            (setting) => setting.fieldName === "members_email"
          )?.allowedDomains?.length > 0 && (
            <>
              <br />
              <label className="email-domain-label">
                Allowed email domains:
              </label>
              <br />
              <div className="email-domain-list">
                {regFormSettings
                  .find((setting) => setting.fieldName === "members_email")
                  ?.allowedDomains.map((domain, index) => (
                    <span key={index} className="email-domain-item">
                      {domain}
                      <IoMdClose
                        title="Delete"
                        onClick={() => {
                          setRegFormSettings(
                            regFormSettings.map((setting) => {
                              if (setting.fieldName === "members_email") {
                                return {
                                  ...setting,
                                  allowedDomains: setting.allowedDomains.filter(
                                    (d) => d !== domain
                                  ),
                                };
                              }
                              return setting;
                            })
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  ))}
              </div>
            </>
          )}
        </Modal>
      )}

      <div className="hosting_bottom">
        <Button className="help-btn" type="button" onClick={() => prevStep()}>
          <FaArrowLeft />
          Go Back
        </Button>
        <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => nextStep("continue")}
        >
          Submit <FaArrowRight />
        </Button>
        {localStorage.getItem("opportunityfrom") && (
          <Button
            className="save-hosting-first-btn editsavebtn"
            type="button"
            onClick={() => nextStep("save")}
          >
            Save <FaSave />
          </Button>
        )}
        <Link to={`/user/hosting`}>
          {" "}
          <Button
            className="transparentbtn float-right mt-10 mr-2"
            type="button"
          >
            Cancel
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default StepNine;
