import React from "react";
import { FaEnvelopeOpen, FaPhoneAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer" id="footershow">
      {/* footer body start */}

      <div className="footer-content">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-5 footerlogo">
                <img
                  src={require("../../../images/footer-logo.png").default}
                  alt="footer-logo"
                />
                <p className="ftr-read-more">
                  Grad Partners is a one stop platform for students,
                  organizations & professionals to connect and engage through
                  workshops, competitive events, mentorship etc. We help
                  students to accelerate their career growth in different
                  aspects of campus life & become corporate ready while
                  organization can benefit with branding among student community
                  and in identifying exceptional talents.
                </p>
                <span style={{ color: "#fff", fontSize: "11px" }}>
                  © Copyright 2024 - All rights reserved by Grad Partners
                </span>
              </div>

              <div className="col-md-4 mt-3">
                <p className="footer-section-heading">Contact Us</p>
                <div className="contact-info-content">
                  <span className="text-white subheading">Partnerships</span>
                  <li className="">
                    <strong>
                      <FaEnvelopeOpen />{" "}
                    </strong>{" "}
                    <a href="mailto:campus@gradpartners.in">
                      campus@gradpartners.in
                    </a>
                  </li>
                  <li className="">
                    <strong>
                      <FaPhoneAlt />{" "}
                    </strong>{" "}
                    <a href="tel:919926236700">+91-9926236700</a>
                  </li>
                </div>
                <div className="contact-info-content">
                  <span className="text-white subheading">Support</span>
                  <li className="">
                    <strong>
                      <FaEnvelopeOpen />{" "}
                    </strong>
                    support@gradpartners.in
                  </li>
                </div>
                <a
                  href="https://www.linkedin.com/company/gradpartners/"
                  target="_blank"
                >
                  <img
                    align="center"
                    className="sociallinks"
                    border="0"
                    src={require("../../../images/Logo-Linkedin.svg").default}
                    alt="LinkedIn"
                    title="LinkedIn"
                    width="54"
                  />{" "}
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/gradpartners/"
                >
                  <img
                    align="center"
                    className="sociallinks"
                    border="0"
                    src={require("../../../images/Logo-Insta.svg").default}
                    alt="Instagram"
                    title="Instagram"
                    width="54"
                  />{" "}
                </a>
                <a target="_blank" href="https://x.com/gradpartners_GP">
                  <img
                    align="center"
                    className="sociallinks"
                    border="0"
                    src={require("../../../images/Logo-TwitterX.svg").default}
                    alt="X"
                    title="X"
                    style={{ width: "33.5px" }}
                  />{" "}
                </a>
                <a target="_blank" href="https://www.youtube.com/@gradpartners">
                  <img
                    align="center"
                    className="sociallinks"
                    border="0"
                    src={require("../../../images/Logo-YouTube.svg").default}
                    alt="YouTube"
                    title="YouTube"
                    width="54"
                  />{" "}
                </a>
                <a target="_blank" href="https://www.facebook.com/gradpartners">
                  <img
                    align="center"
                    className="sociallinks"
                    border="0"
                    src={require("../../../images/Logo-Facebook.svg").default}
                    alt="facebook"
                    title="facebook"
                    width="54"
                  />{" "}
                </a>
              </div>

              <div className="col-md-3 mt-3">
                <p className="footer-section-heading">Important Links</p>
                <div className="contact-info-content">
                  <NavLink className="about-us" to="/about">
                    {" "}
                    <li>About Us</li>
                  </NavLink>
                  {/* <li>Testimonials</li> */}
                  <NavLink className="about-us" to="/terms-amd-conditions">
                    {" "}
                    <li>Terms & Conditions</li>
                  </NavLink>
                  <NavLink className="about-us" to="/privacy-policy">
                    {" "}
                    <li>Privacy Policy</li>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
        <div className= "footer-body">
            
            <div className= "footer-content col">
                
                <div className="Explore col-sm-4" >
                    <img src={require('../../../images/footer-logo.png').default} alt="footer-logo" />
                    
                </div>

                <div className="contact-info col-sm-4" >
                    <p className='footer-section-heading'>Contact Info</p>
                    <div className="contact-info-content">
                        <li><a href="#" className="location"><img src={require('../../../images/footer-location-icon.png').default} alt="footer-location-icon" />Q5/20, DLF Phase II,  Gurugram-122008, Haryana </a></li>
                        <li><a href="#" className="contact-number"><img src={require('../../../images/footer-telephone-icon.png').default} alt="footer-telephone-icon" />0124-4019726</a></li>
                        <li><a href="#" className="mail"><img src={require('../../../images/footer-email-icon.png').default} alt="footer-email-icon" />support@gradpartners.in</a></li>
                    </div>
                </div>
                
                <div className="careers col-sm-4" >
                    <p className='footer-section-heading'>Important Links</p>
                    <div className="career-content">
                        <li><p><FaCheckCircle />Contact</p></li>
                        <li><p><FaCheckCircle />Testimonials</p></li>
                        <li><p><FaCheckCircle />Content Management Intern</p></li>
                        <li><p><FaCheckCircle />Web Development Intern</p></li>
                    </div>
                </div>

            </div>
        </div> */}
      {/* footer body end */}

      {/* <div className= "copyright">
            <p>© Copyright 2021 All Rights Reserved by Grad Partners</p>
        </div> */}
    </div>
  );
}

export default Footer;
